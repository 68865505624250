import { SegmentStatus, CEUType, SegmentType, TaskSortOrder, CourseEventStatus, RecordingMethod, FileType, CourseSpeakerType, OrderBy, ReportingPeriod, EventOrderBy, PageElementType, OrganizationRole, PackageRequirement, InOut, CEUScanMode, MobileUserType, YesNoUnsure, PaperApprovalStatus, PaperActivityType, RecipientType, OrganizationIntegrationType, CEUInstructorStatus, PaperSubmissionSort, BadgeElementType, AssetType, VoucherType, ExhibitType, HorizontalAlignment, RegistrantSort, FontStyle, MembershipStatus, ConferenceModality, MemberProfileSort, RegistrationFieldType, BadgeSize, ConferenceConnectionState, ConferenceMobileTileType, PushNotificationAction, CampaignStatus, CampaignType, CampaignListType, SpreadsheetImportType, SurveyState, FilterOperator, LedgerEntryType, ReaderMode, ParticipationModality, RegistrationModality, ProgramRuleType, RoomBillingMode, AdCampaignType, EmploymentType, CorporateStructure, CompanyType, Experience, EmployeeCount, PayInterval, Education, Currency, ConferenceTaskStatusType, WowzaState } from './generatedModels';

export enum SegmentStatusEnum {
NotStarted,
InProgress,
Completed,
Failed,
MaxQuizAttempts,
}
export enum CEUTypeEnum {
Standard,
Ethics,
Supervision,
}
export enum SegmentTypeEnum {
Video,
Assessment,
}
export enum TaskSortOrderEnum {
Default,
DueDate,
Assignee,
}
export enum CourseEventStatusEnum {
InReview,
Rejected,
Draft,
PendingRecording,
Published,
PublishedInternal,
Unlisted,
ReleaseWithConference,
Archived,
NotForSale,
TechnicalIssue,
}
export enum RecordingMethodEnum {
Self,
Remote,
Studio,
}
export enum FileTypeEnum {
Pdf,
Word,
Excel,
Powerpoint,
Image,
Zip,
Unknown,
}
export enum CourseSpeakerTypeEnum {
Individual,
Panel,
}
export enum OrderByEnum {
CreatedDate,
ModifiedDate,
Name,
MissingCEUQuestions,
OriginalAirDate,
}
export enum ReportingPeriodEnum {
Today,
Last7Days,
Last4Weeks,
Last3Months,
Last12Months,
MonthToDate,
QuarterToDate,
YearToDate,
AllTime,
}
export enum EventOrderByEnum {
CreatedDate,
ModifiedDate,
EventDate,
Name,
}
export enum PageElementTypeEnum {
HTMLContent,
OnDemandSearch,
PackageListing,
ExhibitListing,
SpeakerListing,
LiveEventListing,
ConferenceDate,
OnDemandListing,
PosterListing,
AdRotator,
Schedule,
ProgramExport,
OrganizationEventListing,
ProductListing,
PDFViewer,
ConferenceListing,
BundleListing,
}
export enum OrganizationRoleEnum {
Admin,
User,
Instructor,
}
export enum PackageRequirementEnum {
None,
EDUAddress,
MembersOnly,
}
export enum InOutEnum {
In,
Out,
}
export enum CEUScanModeEnum {
Search,
Scan,
}
export enum MobileUserTypeEnum {
iOS,
Android,
}
export enum YesNoUnsureEnum {
No,
Yes,
Unsure,
}
export enum PaperApprovalStatusEnum {
Draft,
Submitted,
InReview,
Accepted,
Rejected,
Withdrawn,
ChangesNeeded,
Finalized,
}
export enum PaperActivityTypeEnum {
StatusChanged,
EmailSent,
EmailReceived,
NoteAdded,
NoteCommentAdded,
AssigneeUpdated,
PropertyUpdated,
ReviewAdded,
}
export enum RecipientTypeEnum {
To,
Cc,
Bcc,
}
export enum OrganizationIntegrationTypeEnum {
MemberClicks,
WordPressGravityForms,
Novi,
HubspotMarketingEvents,
MemberPress,
}
export enum CEUInstructorStatusEnum {
Unknown,
Confirmed,
Needed,
}
export enum PaperSubmissionSortEnum {
SubmissionDate,
AverageRating,
Duration,
Alphabetical,
Type,
ReviewCount,
}
export enum BadgeElementTypeEnum {
Text,
Image,
QRCode,
AddonList,
EventAddonList,
}
export enum AssetTypeEnum {
AnyFile,
AnyImage,
Vector,
VectorPDF,
Raster,
RasterPDF,
Video,
PDF,
Word,
}
export enum VoucherTypeEnum {
ComplmentaryRegistration,
ExhibitBoothPass,
Generic,
}
export enum ExhibitTypeEnum {
OnSiteAndOnline,
OnSiteOnly,
OnlineOnly,
}
export enum HorizontalAlignmentEnum {
Left,
Right,
Center,
}
export enum RegistrantSortEnum {
RegistrationDateAsc,
RegistrationDateDesc,
Alphabetical,
Package,
}
export enum FontStyleEnum {
Regular,
Bold,
Italic,
BoldItalic,
}
export enum MembershipStatusEnum {
Active,
Inactive,
Expired,
}
export enum ConferenceModalityEnum {
Hybrid,
Virtual,
InPerson,
HybridAV,
}
export enum MemberProfileSortEnum {
NameAsc,
NameDesc,
}
export enum RegistrationFieldTypeEnum {
Text,
SingleSelect,
MultiSelect,
}
export enum BadgeSizeEnum {
FourByThree,
FourBySix,
}
export enum ConferenceConnectionStateEnum {
Pending,
Accepted,
Rejected,
}
export enum ConferenceMobileTileTypeEnum {
PresenterListing,
SessionListing,
SponsorListing,
ExhibitListing,
MapView,
AttendeeListing,
PDFListing,
WebView,
HTMLText,
ChatGroups,
SocialWall,
Announcements,
Survey,
Page,
}
export enum PushNotificationActionEnum {
ConnectionRequestSent,
ConnectionRequestAccepted,
ChatReceived,
ChatGroupRequested,
NewAnnouncement,
}
export enum CampaignStatusEnum {
Draft,
Scheduled,
Sending,
Sent,
Archived,
}
export enum CampaignTypeEnum {
Email,
SMS,
Push,
}
export enum CampaignListTypeEnum {
Dynamic,
Static,
}
export enum SpreadsheetImportTypeEnum {
ConferenceRegistrant,
EventRegistrant,
Employee,
}
export enum SurveyStateEnum {
Draft,
Published,
Archived,
}
export enum FilterOperatorEnum {
EqualTo,
NotEqualTo,
}
export enum LedgerEntryTypeEnum {
CourseSold,
Referral,
EventRegistration,
Refund,
Bundle,
}
export enum ReaderModeEnum {
ScanIn,
ScanOut,
}
export enum ParticipationModalityEnum {
None,
InPerson,
LiveStream,
OnDemand,
}
export enum RegistrationModalityEnum {
InPerson,
LiveStream,
CheckedIn,
NotCheckedIn,
}
export enum ProgramRuleTypeEnum {
MatchAllEvents,
SourcePaperType,
HasTag,
TitleContains,
}
export enum RoomBillingModeEnum {
SelfPay,
RoomToMaster,
AllChargesToMaster,
}
export enum AdCampaignTypeEnum {
Image,
Text,
Video,
}
export enum EmploymentTypeEnum {
FullTime,
PartTime,
Freelance,
Internship,
Volunteer,
}
export enum CorporateStructureEnum {
Public,
Private,
NonProfit,
}
export enum CompanyTypeEnum {
Employer,
Recruiter,
NonProfit,
JobBoard,
}
export enum ExperienceEnum {
EntryLevel,
MidLevel,
SeniorLevel,
Director,
Executive,
}
export enum EmployeeCountEnum {
LessThanFive,
FiveToTwenty,
TwentyToFifty,
FiftyToOneHundred,
OneHundredToFiveHundred,
FiveHundredToOneThousand,
OneThousandToTenThousand,
TenThousandPlus,
}
export enum PayIntervalEnum {
NA,
Hourly,
Annually,
}
export enum EducationEnum {
Unspecified,
SomeHighSchool,
HighSchool,
VocationalHighSchoolDiploma,
Certification,
Vocational,
SomeCollege,
Professional,
VocationalDegree,
Associate,
Bachelors,
Masters,
Doctorate,
}
export enum CurrencyEnum {
USD,
EUR,
GBP,
CAD,
AUD,
INR,
}
export enum ConferenceTaskStatusTypeEnum {
NotStarted,
InProgress,
Completed,
}
export enum WowzaStateEnum {
Started,
Stopped,
Starting,
Stopping,
Resetting,
}
export enum StrSegmentStatusEnum {
NotStarted='NotStarted',
InProgress='InProgress',
Completed='Completed',
Failed='Failed',
MaxQuizAttempts='MaxQuizAttempts',
}
export enum StrCEUTypeEnum {
Standard='Standard',
Ethics='Ethics',
Supervision='Supervision',
}
export enum StrSegmentTypeEnum {
Video='Video',
Assessment='Assessment',
}
export enum StrTaskSortOrderEnum {
Default='Default',
DueDate='DueDate',
Assignee='Assignee',
}
export enum StrCourseEventStatusEnum {
InReview='InReview',
Rejected='Rejected',
Draft='Draft',
PendingRecording='PendingRecording',
Published='Published',
PublishedInternal='PublishedInternal',
Unlisted='Unlisted',
ReleaseWithConference='ReleaseWithConference',
Archived='Archived',
NotForSale='NotForSale',
TechnicalIssue='TechnicalIssue',
}
export enum StrRecordingMethodEnum {
Self='Self',
Remote='Remote',
Studio='Studio',
}
export enum StrFileTypeEnum {
Pdf='Pdf',
Word='Word',
Excel='Excel',
Powerpoint='Powerpoint',
Image='Image',
Zip='Zip',
Unknown='Unknown',
}
export enum StrCourseSpeakerTypeEnum {
Individual='Individual',
Panel='Panel',
}
export enum StrOrderByEnum {
CreatedDate='CreatedDate',
ModifiedDate='ModifiedDate',
Name='Name',
MissingCEUQuestions='MissingCEUQuestions',
OriginalAirDate='OriginalAirDate',
}
export enum StrReportingPeriodEnum {
Today='Today',
Last7Days='Last7Days',
Last4Weeks='Last4Weeks',
Last3Months='Last3Months',
Last12Months='Last12Months',
MonthToDate='MonthToDate',
QuarterToDate='QuarterToDate',
YearToDate='YearToDate',
AllTime='AllTime',
}
export enum StrEventOrderByEnum {
CreatedDate='CreatedDate',
ModifiedDate='ModifiedDate',
EventDate='EventDate',
Name='Name',
}
export enum StrPageElementTypeEnum {
HTMLContent='HTMLContent',
OnDemandSearch='OnDemandSearch',
PackageListing='PackageListing',
ExhibitListing='ExhibitListing',
SpeakerListing='SpeakerListing',
LiveEventListing='LiveEventListing',
ConferenceDate='ConferenceDate',
OnDemandListing='OnDemandListing',
PosterListing='PosterListing',
AdRotator='AdRotator',
Schedule='Schedule',
ProgramExport='ProgramExport',
OrganizationEventListing='OrganizationEventListing',
ProductListing='ProductListing',
PDFViewer='PDFViewer',
ConferenceListing='ConferenceListing',
BundleListing='BundleListing',
}
export enum StrOrganizationRoleEnum {
Admin='Admin',
User='User',
Instructor='Instructor',
}
export enum StrPackageRequirementEnum {
None='None',
EDUAddress='EDUAddress',
MembersOnly='MembersOnly',
}
export enum StrInOutEnum {
In='In',
Out='Out',
}
export enum StrCEUScanModeEnum {
Search='Search',
Scan='Scan',
}
export enum StrMobileUserTypeEnum {
iOS='iOS',
Android='Android',
}
export enum StrYesNoUnsureEnum {
No='No',
Yes='Yes',
Unsure='Unsure',
}
export enum StrPaperApprovalStatusEnum {
Draft='Draft',
Submitted='Submitted',
InReview='InReview',
Accepted='Accepted',
Rejected='Rejected',
Withdrawn='Withdrawn',
ChangesNeeded='ChangesNeeded',
Finalized='Finalized',
}
export enum StrPaperActivityTypeEnum {
StatusChanged='StatusChanged',
EmailSent='EmailSent',
EmailReceived='EmailReceived',
NoteAdded='NoteAdded',
NoteCommentAdded='NoteCommentAdded',
AssigneeUpdated='AssigneeUpdated',
PropertyUpdated='PropertyUpdated',
ReviewAdded='ReviewAdded',
}
export enum StrRecipientTypeEnum {
To='To',
Cc='Cc',
Bcc='Bcc',
}
export enum StrOrganizationIntegrationTypeEnum {
MemberClicks='MemberClicks',
WordPressGravityForms='WordPressGravityForms',
Novi='Novi',
HubspotMarketingEvents='HubspotMarketingEvents',
MemberPress='MemberPress',
}
export enum StrCEUInstructorStatusEnum {
Unknown='Unknown',
Confirmed='Confirmed',
Needed='Needed',
}
export enum StrPaperSubmissionSortEnum {
SubmissionDate='SubmissionDate',
AverageRating='AverageRating',
Duration='Duration',
Alphabetical='Alphabetical',
Type='Type',
ReviewCount='ReviewCount',
}
export enum StrBadgeElementTypeEnum {
Text='Text',
Image='Image',
QRCode='QRCode',
AddonList='AddonList',
EventAddonList='EventAddonList',
}
export enum StrAssetTypeEnum {
AnyFile='AnyFile',
AnyImage='AnyImage',
Vector='Vector',
VectorPDF='VectorPDF',
Raster='Raster',
RasterPDF='RasterPDF',
Video='Video',
PDF='PDF',
Word='Word',
}
export enum StrVoucherTypeEnum {
ComplmentaryRegistration='ComplmentaryRegistration',
ExhibitBoothPass='ExhibitBoothPass',
Generic='Generic',
}
export enum StrExhibitTypeEnum {
OnSiteAndOnline='OnSiteAndOnline',
OnSiteOnly='OnSiteOnly',
OnlineOnly='OnlineOnly',
}
export enum StrHorizontalAlignmentEnum {
Left='Left',
Right='Right',
Center='Center',
}
export enum StrRegistrantSortEnum {
RegistrationDateAsc='RegistrationDateAsc',
RegistrationDateDesc='RegistrationDateDesc',
Alphabetical='Alphabetical',
Package='Package',
}
export enum StrFontStyleEnum {
Regular='Regular',
Bold='Bold',
Italic='Italic',
BoldItalic='BoldItalic',
}
export enum StrMembershipStatusEnum {
Active='Active',
Inactive='Inactive',
Expired='Expired',
}
export enum StrConferenceModalityEnum {
Hybrid='Hybrid',
Virtual='Virtual',
InPerson='InPerson',
HybridAV='HybridAV',
}
export enum StrMemberProfileSortEnum {
NameAsc='NameAsc',
NameDesc='NameDesc',
}
export enum StrRegistrationFieldTypeEnum {
Text='Text',
SingleSelect='SingleSelect',
MultiSelect='MultiSelect',
}
export enum StrBadgeSizeEnum {
FourByThree='FourByThree',
FourBySix='FourBySix',
}
export enum StrConferenceConnectionStateEnum {
Pending='Pending',
Accepted='Accepted',
Rejected='Rejected',
}
export enum StrConferenceMobileTileTypeEnum {
PresenterListing='PresenterListing',
SessionListing='SessionListing',
SponsorListing='SponsorListing',
ExhibitListing='ExhibitListing',
MapView='MapView',
AttendeeListing='AttendeeListing',
PDFListing='PDFListing',
WebView='WebView',
HTMLText='HTMLText',
ChatGroups='ChatGroups',
SocialWall='SocialWall',
Announcements='Announcements',
Survey='Survey',
Page='Page',
}
export enum StrPushNotificationActionEnum {
ConnectionRequestSent='ConnectionRequestSent',
ConnectionRequestAccepted='ConnectionRequestAccepted',
ChatReceived='ChatReceived',
ChatGroupRequested='ChatGroupRequested',
NewAnnouncement='NewAnnouncement',
}
export enum StrCampaignStatusEnum {
Draft='Draft',
Scheduled='Scheduled',
Sending='Sending',
Sent='Sent',
Archived='Archived',
}
export enum StrCampaignTypeEnum {
Email='Email',
SMS='SMS',
Push='Push',
}
export enum StrCampaignListTypeEnum {
Dynamic='Dynamic',
Static='Static',
}
export enum StrSpreadsheetImportTypeEnum {
ConferenceRegistrant='ConferenceRegistrant',
EventRegistrant='EventRegistrant',
Employee='Employee',
}
export enum StrSurveyStateEnum {
Draft='Draft',
Published='Published',
Archived='Archived',
}
export enum StrFilterOperatorEnum {
EqualTo='EqualTo',
NotEqualTo='NotEqualTo',
}
export enum StrLedgerEntryTypeEnum {
CourseSold='CourseSold',
Referral='Referral',
EventRegistration='EventRegistration',
Refund='Refund',
Bundle='Bundle',
}
export enum StrReaderModeEnum {
ScanIn='ScanIn',
ScanOut='ScanOut',
}
export enum StrParticipationModalityEnum {
None='None',
InPerson='InPerson',
LiveStream='LiveStream',
OnDemand='OnDemand',
}
export enum StrRegistrationModalityEnum {
InPerson='InPerson',
LiveStream='LiveStream',
CheckedIn='CheckedIn',
NotCheckedIn='NotCheckedIn',
}
export enum StrProgramRuleTypeEnum {
MatchAllEvents='MatchAllEvents',
SourcePaperType='SourcePaperType',
HasTag='HasTag',
TitleContains='TitleContains',
}
export enum StrRoomBillingModeEnum {
SelfPay='SelfPay',
RoomToMaster='RoomToMaster',
AllChargesToMaster='AllChargesToMaster',
}
export enum StrAdCampaignTypeEnum {
Image='Image',
Text='Text',
Video='Video',
}
export enum StrEmploymentTypeEnum {
FullTime='FullTime',
PartTime='PartTime',
Freelance='Freelance',
Internship='Internship',
Volunteer='Volunteer',
}
export enum StrCorporateStructureEnum {
Public='Public',
Private='Private',
NonProfit='NonProfit',
}
export enum StrCompanyTypeEnum {
Employer='Employer',
Recruiter='Recruiter',
NonProfit='NonProfit',
JobBoard='JobBoard',
}
export enum StrExperienceEnum {
EntryLevel='EntryLevel',
MidLevel='MidLevel',
SeniorLevel='SeniorLevel',
Director='Director',
Executive='Executive',
}
export enum StrEmployeeCountEnum {
LessThanFive='LessThanFive',
FiveToTwenty='FiveToTwenty',
TwentyToFifty='TwentyToFifty',
FiftyToOneHundred='FiftyToOneHundred',
OneHundredToFiveHundred='OneHundredToFiveHundred',
FiveHundredToOneThousand='FiveHundredToOneThousand',
OneThousandToTenThousand='OneThousandToTenThousand',
TenThousandPlus='TenThousandPlus',
}
export enum StrPayIntervalEnum {
NA='NA',
Hourly='Hourly',
Annually='Annually',
}
export enum StrEducationEnum {
Unspecified='Unspecified',
SomeHighSchool='SomeHighSchool',
HighSchool='HighSchool',
VocationalHighSchoolDiploma='VocationalHighSchoolDiploma',
Certification='Certification',
Vocational='Vocational',
SomeCollege='SomeCollege',
Professional='Professional',
VocationalDegree='VocationalDegree',
Associate='Associate',
Bachelors='Bachelors',
Masters='Masters',
Doctorate='Doctorate',
}
export enum StrCurrencyEnum {
USD='USD',
EUR='EUR',
GBP='GBP',
CAD='CAD',
AUD='AUD',
INR='INR',
}
export enum StrConferenceTaskStatusTypeEnum {
NotStarted='NotStarted',
InProgress='InProgress',
Completed='Completed',
}
export enum StrWowzaStateEnum {
Started='Started',
Stopped='Stopped',
Starting='Starting',
Stopping='Stopping',
Resetting='Resetting',
}

export class Enums {
  public static SegmentStatusEnum = new Map<number, string>([
    [SegmentStatus.NotStarted, 'NotStarted'],
    [SegmentStatus.InProgress, 'InProgress'],
    [SegmentStatus.Completed, 'Completed'],
    [SegmentStatus.Failed, 'Failed'],
    [SegmentStatus.MaxQuizAttempts, 'MaxQuizAttempts'],
  ]);

  public static CEUTypeEnum = new Map<number, string>([
    [CEUType.Standard, 'Standard'],
    [CEUType.Ethics, 'Ethics'],
    [CEUType.Supervision, 'Supervision'],
  ]);

  public static SegmentTypeEnum = new Map<number, string>([
    [SegmentType.Video, 'Video'],
    [SegmentType.Assessment, 'Assessment'],
  ]);

  public static TaskSortOrderEnum = new Map<number, string>([
    [TaskSortOrder.Default, 'Default'],
    [TaskSortOrder.DueDate, 'DueDate'],
    [TaskSortOrder.Assignee, 'Assignee'],
  ]);

  public static CourseEventStatusEnum = new Map<number, string>([
    [CourseEventStatus.InReview, 'In Review'],
    [CourseEventStatus.Rejected, 'Rejected'],
    [CourseEventStatus.Draft, 'Draft'],
    [CourseEventStatus.PendingRecording, 'Pending Recording'],
    [CourseEventStatus.Published, 'Published'],
    [CourseEventStatus.PublishedInternal, 'Published Internal'],
    [CourseEventStatus.Unlisted, 'Unlisted'],
    [CourseEventStatus.ReleaseWithConference, 'Released With Conference'],
    [CourseEventStatus.Archived, 'Archived'],
    [CourseEventStatus.NotForSale, 'Not for Sale'],
    [CourseEventStatus.TechnicalIssue, 'Technical Issue'],
  ]);

  public static RecordingMethodEnum = new Map<number, string>([
    [RecordingMethod.Self, 'Self'],
    [RecordingMethod.Remote, 'Remote'],
    [RecordingMethod.Studio, 'Studio'],
  ]);

  public static FileTypeEnum = new Map<number, string>([
    [FileType.Pdf, 'Pdf'],
    [FileType.Word, 'Word'],
    [FileType.Excel, 'Excel'],
    [FileType.Powerpoint, 'Powerpoint'],
    [FileType.Image, 'Image'],
    [FileType.Zip, 'Zip'],
    [FileType.Unknown, 'Unknown'],
  ]);

  public static CourseSpeakerTypeEnum = new Map<number, string>([
    [CourseSpeakerType.Individual, 'Individual'],
    [CourseSpeakerType.Panel, 'Panel'],
  ]);

  public static OrderByEnum = new Map<number, string>([
    [OrderBy.CreatedDate, 'Created Date'],
    [OrderBy.ModifiedDate, 'Modified Date'],
    [OrderBy.Name, 'Name'],
    [OrderBy.MissingCEUQuestions, 'MissingCEUQuestions'],
    [OrderBy.OriginalAirDate, 'OriginalAirDate'],
  ]);

  public static ReportingPeriodEnum = new Map<number, string>([
    [ReportingPeriod.Today, 'Today'],
    [ReportingPeriod.Last7Days, 'Last 7 days'],
    [ReportingPeriod.Last4Weeks, 'Last 4 weeks'],
    [ReportingPeriod.Last3Months, 'Last 3 months'],
    [ReportingPeriod.Last12Months, 'Last 12 months'],
    [ReportingPeriod.MonthToDate, 'Month to date'],
    [ReportingPeriod.QuarterToDate, 'Quarter to date'],
    [ReportingPeriod.YearToDate, 'Year to date'],
    [ReportingPeriod.AllTime, 'All time'],
  ]);

  public static EventOrderByEnum = new Map<number, string>([
    [EventOrderBy.CreatedDate, 'Created Date'],
    [EventOrderBy.ModifiedDate, 'Modified Date'],
    [EventOrderBy.EventDate, 'Event Date'],
    [EventOrderBy.Name, 'Name'],
  ]);

  public static PageElementTypeEnum = new Map<number, string>([
    [PageElementType.HTMLContent, 'HTMLContent'],
    [PageElementType.OnDemandSearch, 'OnDemandSearch'],
    [PageElementType.PackageListing, 'PackageListing'],
    [PageElementType.ExhibitListing, 'ExhibitListing'],
    [PageElementType.SpeakerListing, 'SpeakerListing'],
    [PageElementType.LiveEventListing, 'LiveEventListing'],
    [PageElementType.ConferenceDate, 'ConferenceDate'],
    [PageElementType.OnDemandListing, 'OnDemandListing'],
    [PageElementType.PosterListing, 'PosterListing'],
    [PageElementType.AdRotator, 'AdRotator'],
    [PageElementType.Schedule, 'Schedule'],
    [PageElementType.ProgramExport, 'ProgramExport'],
    [PageElementType.OrganizationEventListing, 'OrganizationEventListing'],
    [PageElementType.ProductListing, 'ProductListing'],
    [PageElementType.PDFViewer, 'PDFViewer'],
    [PageElementType.ConferenceListing, 'ConferenceListing'],
    [PageElementType.BundleListing, 'BundleListing'],
  ]);

  public static OrganizationRoleEnum = new Map<number, string>([
    [OrganizationRole.Admin, 'Admin'],
    [OrganizationRole.User, 'User'],
    [OrganizationRole.Instructor, 'Instructor'],
  ]);

  public static PackageRequirementEnum = new Map<number, string>([
    [PackageRequirement.None, 'None'],
    [PackageRequirement.EDUAddress, 'EDUAddress'],
    [PackageRequirement.MembersOnly, 'MembersOnly'],
  ]);

  public static InOutEnum = new Map<number, string>([
    [InOut.In, 'In'],
    [InOut.Out, 'Out'],
  ]);

  public static CEUScanModeEnum = new Map<number, string>([
    [CEUScanMode.Search, 'Search'],
    [CEUScanMode.Scan, 'Scan'],
  ]);

  public static MobileUserTypeEnum = new Map<number, string>([
    [MobileUserType.iOS, 'iOS'],
    [MobileUserType.Android, 'Android'],
  ]);

  public static YesNoUnsureEnum = new Map<number, string>([
    [YesNoUnsure.No, 'No'],
    [YesNoUnsure.Yes, 'Yes'],
    [YesNoUnsure.Unsure, 'Unsure'],
  ]);

  public static PaperApprovalStatusEnum = new Map<number, string>([
    [PaperApprovalStatus.Draft, 'Draft'],
    [PaperApprovalStatus.Submitted, 'Submitted'],
    [PaperApprovalStatus.InReview, 'In Review'],
    [PaperApprovalStatus.Accepted, 'Accepted'],
    [PaperApprovalStatus.Rejected, 'Rejected'],
    [PaperApprovalStatus.Withdrawn, 'Withdrawn'],
    [PaperApprovalStatus.ChangesNeeded, 'Changes Needed'],
    [PaperApprovalStatus.Finalized, 'Finalized'],
  ]);

  public static PaperActivityTypeEnum = new Map<number, string>([
    [PaperActivityType.StatusChanged, 'StatusChanged'],
    [PaperActivityType.EmailSent, 'EmailSent'],
    [PaperActivityType.EmailReceived, 'EmailReceived'],
    [PaperActivityType.NoteAdded, 'NoteAdded'],
    [PaperActivityType.NoteCommentAdded, 'NoteCommentAdded'],
    [PaperActivityType.AssigneeUpdated, 'AssigneeUpdated'],
    [PaperActivityType.PropertyUpdated, 'PropertyUpdated'],
    [PaperActivityType.ReviewAdded, 'ReviewAdded'],
  ]);

  public static RecipientTypeEnum = new Map<number, string>([
    [RecipientType.To, 'To'],
    [RecipientType.Cc, 'Cc'],
    [RecipientType.Bcc, 'Bcc'],
  ]);

  public static OrganizationIntegrationTypeEnum = new Map<number, string>([
    [OrganizationIntegrationType.MemberClicks, 'MemberClicks'],
    [OrganizationIntegrationType.WordPressGravityForms, 'WordPress: Gravity Forms'],
    [OrganizationIntegrationType.Novi, 'Novi'],
    [OrganizationIntegrationType.HubspotMarketingEvents, 'Hubspot Marketing Events'],
    [OrganizationIntegrationType.MemberPress, 'MemberPress'],
  ]);

  public static CEUInstructorStatusEnum = new Map<number, string>([
    [CEUInstructorStatus.Unknown, 'Unknown'],
    [CEUInstructorStatus.Confirmed, 'Confirmed'],
    [CEUInstructorStatus.Needed, 'Needed'],
  ]);

  public static PaperSubmissionSortEnum = new Map<number, string>([
    [PaperSubmissionSort.SubmissionDate, 'Submission Date'],
    [PaperSubmissionSort.AverageRating, 'Average Score'],
    [PaperSubmissionSort.Duration, 'Duration'],
    [PaperSubmissionSort.Alphabetical, 'Alphabetical'],
    [PaperSubmissionSort.Type, 'Type'],
    [PaperSubmissionSort.ReviewCount, 'Number of Reviews'],
  ]);

  public static BadgeElementTypeEnum = new Map<number, string>([
    [BadgeElementType.Text, 'Text'],
    [BadgeElementType.Image, 'Image'],
    [BadgeElementType.QRCode, 'QRCode'],
    [BadgeElementType.AddonList, 'AddonList'],
    [BadgeElementType.EventAddonList, 'EventAddonList'],
  ]);

  public static AssetTypeEnum = new Map<number, string>([
    [AssetType.AnyFile, 'Any File Format'],
    [AssetType.AnyImage, 'Any Image Format'],
    [AssetType.Vector, 'Vector (EPS, AI, SVG, PSD)'],
    [AssetType.VectorPDF, 'Vector + PDF (EPS, AI, SVG, PSD, PDF)'],
    [AssetType.Raster, 'Raster (PNG, JPG, GIF, TIFF)'],
    [AssetType.RasterPDF, 'Raster + PDF (PNG, JPG, GIF, TIFF, PDF)'],
    [AssetType.Video, 'Video (MOV, MP4, AVI)'],
    [AssetType.PDF, 'PDF'],
    [AssetType.Word, 'Word (doc, docx)'],
  ]);

  public static VoucherTypeEnum = new Map<number, string>([
    [VoucherType.ComplmentaryRegistration, 'Complimentary Registration'],
    [VoucherType.ExhibitBoothPass, 'Exhibit Booth Pass'],
    [VoucherType.Generic, 'Generic'],
  ]);

  public static ExhibitTypeEnum = new Map<number, string>([
    [ExhibitType.OnSiteAndOnline, 'On-Site & Online'],
    [ExhibitType.OnSiteOnly, 'On-Site Only'],
    [ExhibitType.OnlineOnly, 'Online Only'],
  ]);

  public static HorizontalAlignmentEnum = new Map<number, string>([
    [HorizontalAlignment.Left, 'Left'],
    [HorizontalAlignment.Right, 'Right'],
    [HorizontalAlignment.Center, 'Center'],
  ]);

  public static RegistrantSortEnum = new Map<number, string>([
    [RegistrantSort.RegistrationDateAsc, 'Registration Date (ASC)'],
    [RegistrantSort.RegistrationDateDesc, 'Registration Date (DESC)'],
    [RegistrantSort.Alphabetical, 'Alphabetical'],
    [RegistrantSort.Package, 'Package'],
  ]);

  public static FontStyleEnum = new Map<number, string>([
    [FontStyle.Regular, 'Regular'],
    [FontStyle.Bold, 'Bold'],
    [FontStyle.Italic, 'Italic'],
    [FontStyle.BoldItalic, 'BoldItalic'],
  ]);

  public static MembershipStatusEnum = new Map<number, string>([
    [MembershipStatus.Active, 'Active'],
    [MembershipStatus.Inactive, 'Inactive'],
    [MembershipStatus.Expired, 'Expired'],
  ]);

  public static ConferenceModalityEnum = new Map<number, string>([
    [ConferenceModality.Hybrid, 'Hybrid'],
    [ConferenceModality.Virtual, 'Virtual'],
    [ConferenceModality.InPerson, 'InPerson'],
    [ConferenceModality.HybridAV, 'HybridAV'],
  ]);

  public static MemberProfileSortEnum = new Map<number, string>([
    [MemberProfileSort.NameAsc, 'Name (ASC)'],
    [MemberProfileSort.NameDesc, 'Name (DESC)'],
  ]);

  public static RegistrationFieldTypeEnum = new Map<number, string>([
    [RegistrationFieldType.Text, 'Text'],
    [RegistrationFieldType.SingleSelect, 'SingleSelect'],
    [RegistrationFieldType.MultiSelect, 'MultiSelect'],
  ]);

  public static BadgeSizeEnum = new Map<number, string>([
    [BadgeSize.FourByThree, 'FourByThree'],
    [BadgeSize.FourBySix, 'FourBySix'],
  ]);

  public static ConferenceConnectionStateEnum = new Map<number, string>([
    [ConferenceConnectionState.Pending, 'Pending'],
    [ConferenceConnectionState.Accepted, 'Accepted'],
    [ConferenceConnectionState.Rejected, 'Rejected'],
  ]);

  public static ConferenceMobileTileTypeEnum = new Map<number, string>([
    [ConferenceMobileTileType.PresenterListing, 'PresenterListing'],
    [ConferenceMobileTileType.SessionListing, 'SessionListing'],
    [ConferenceMobileTileType.SponsorListing, 'SponsorListing'],
    [ConferenceMobileTileType.ExhibitListing, 'ExhibitListing'],
    [ConferenceMobileTileType.MapView, 'MapView'],
    [ConferenceMobileTileType.AttendeeListing, 'AttendeeListing'],
    [ConferenceMobileTileType.PDFListing, 'PDFListing'],
    [ConferenceMobileTileType.WebView, 'WebView'],
    [ConferenceMobileTileType.HTMLText, 'HTMLText'],
    [ConferenceMobileTileType.ChatGroups, 'ChatGroups'],
    [ConferenceMobileTileType.SocialWall, 'SocialWall'],
    [ConferenceMobileTileType.Announcements, 'Announcements'],
    [ConferenceMobileTileType.Survey, 'Survey'],
    [ConferenceMobileTileType.Page, 'Page'],
  ]);

  public static PushNotificationActionEnum = new Map<number, string>([
    [PushNotificationAction.ConnectionRequestSent, 'ConnectionRequestSent'],
    [PushNotificationAction.ConnectionRequestAccepted, 'ConnectionRequestAccepted'],
    [PushNotificationAction.ChatReceived, 'ChatReceived'],
    [PushNotificationAction.ChatGroupRequested, 'ChatGroupRequested'],
    [PushNotificationAction.NewAnnouncement, 'NewAnnouncement'],
  ]);

  public static CampaignStatusEnum = new Map<number, string>([
    [CampaignStatus.Draft, 'Draft'],
    [CampaignStatus.Scheduled, 'Scheduled'],
    [CampaignStatus.Sending, 'Sending'],
    [CampaignStatus.Sent, 'Sent'],
    [CampaignStatus.Archived, 'Archived'],
  ]);

  public static CampaignTypeEnum = new Map<number, string>([
    [CampaignType.Email, 'Email'],
    [CampaignType.SMS, 'SMS'],
    [CampaignType.Push, 'Push'],
  ]);

  public static CampaignListTypeEnum = new Map<number, string>([
    [CampaignListType.Dynamic, 'Dynamic'],
    [CampaignListType.Static, 'Static'],
  ]);

  public static SpreadsheetImportTypeEnum = new Map<number, string>([
    [SpreadsheetImportType.ConferenceRegistrant, 'ConferenceRegistrant'],
    [SpreadsheetImportType.EventRegistrant, 'EventRegistrant'],
    [SpreadsheetImportType.Employee, 'Employee'],
  ]);

  public static SurveyStateEnum = new Map<number, string>([
    [SurveyState.Draft, 'Draft'],
    [SurveyState.Published, 'Published'],
    [SurveyState.Archived, 'Archived'],
  ]);

  public static FilterOperatorEnum = new Map<number, string>([
    [FilterOperator.EqualTo, 'EqualTo'],
    [FilterOperator.NotEqualTo, 'NotEqualTo'],
  ]);

  public static LedgerEntryTypeEnum = new Map<number, string>([
    [LedgerEntryType.CourseSold, 'CourseSold'],
    [LedgerEntryType.Referral, 'Referral'],
    [LedgerEntryType.EventRegistration, 'EventRegistration'],
    [LedgerEntryType.Refund, 'Refund'],
    [LedgerEntryType.Bundle, 'Bundle'],
  ]);

  public static ReaderModeEnum = new Map<number, string>([
    [ReaderMode.ScanIn, 'Scan In'],
    [ReaderMode.ScanOut, 'Scan Out'],
  ]);

  public static ParticipationModalityEnum = new Map<number, string>([
    [ParticipationModality.None, 'N/A'],
    [ParticipationModality.InPerson, 'In Person'],
    [ParticipationModality.LiveStream, 'Live Stream'],
    [ParticipationModality.OnDemand, 'On Demand'],
  ]);

  public static RegistrationModalityEnum = new Map<number, string>([
    [RegistrationModality.InPerson, 'InPerson'],
    [RegistrationModality.LiveStream, 'LiveStream'],
    [RegistrationModality.CheckedIn, 'CheckedIn'],
    [RegistrationModality.NotCheckedIn, 'NotCheckedIn'],
  ]);

  public static ProgramRuleTypeEnum = new Map<number, string>([
    [ProgramRuleType.MatchAllEvents, 'Match All Events'],
    [ProgramRuleType.SourcePaperType, 'Source Paper Type Is'],
    [ProgramRuleType.HasTag, 'Has Tag'],
    [ProgramRuleType.TitleContains, 'Title Contains'],
  ]);

  public static RoomBillingModeEnum = new Map<number, string>([
    [RoomBillingMode.SelfPay, 'Self Pay'],
    [RoomBillingMode.RoomToMaster, 'Room only to Master'],
    [RoomBillingMode.AllChargesToMaster, 'All Charges to Master'],
  ]);

  public static AdCampaignTypeEnum = new Map<number, string>([
    [AdCampaignType.Image, 'Banner'],
    [AdCampaignType.Text, 'Text'],
    [AdCampaignType.Video, 'Video'],
  ]);

  public static EmploymentTypeEnum = new Map<number, string>([
    [EmploymentType.FullTime, 'Full-Time'],
    [EmploymentType.PartTime, 'Part-Time'],
    [EmploymentType.Freelance, 'Freelance'],
    [EmploymentType.Internship, 'Internship'],
    [EmploymentType.Volunteer, 'Volunteer'],
  ]);

  public static CorporateStructureEnum = new Map<number, string>([
    [CorporateStructure.Public, 'Public'],
    [CorporateStructure.Private, 'Private'],
    [CorporateStructure.NonProfit, 'Non-Profit'],
  ]);

  public static CompanyTypeEnum = new Map<number, string>([
    [CompanyType.Employer, 'Employer'],
    [CompanyType.Recruiter, 'Recruiter'],
    [CompanyType.NonProfit, 'Non-Profit'],
    [CompanyType.JobBoard, 'Job Board'],
  ]);

  public static ExperienceEnum = new Map<number, string>([
    [Experience.EntryLevel, 'Entry Level'],
    [Experience.MidLevel, 'Mid Level'],
    [Experience.SeniorLevel, 'Senior Level'],
    [Experience.Director, 'Director'],
    [Experience.Executive, 'Executive'],
  ]);

  public static EmployeeCountEnum = new Map<number, string>([
    [EmployeeCount.LessThanFive, 'Less than 5'],
    [EmployeeCount.FiveToTwenty, '5 to 20'],
    [EmployeeCount.TwentyToFifty, '20 to 50'],
    [EmployeeCount.FiftyToOneHundred, '50 to 100'],
    [EmployeeCount.OneHundredToFiveHundred, '100 to 500'],
    [EmployeeCount.FiveHundredToOneThousand, '500 to 1,000'],
    [EmployeeCount.OneThousandToTenThousand, '1,000 to 10,000'],
    [EmployeeCount.TenThousandPlus, 'More than 10,000'],
  ]);

  public static PayIntervalEnum = new Map<number, string>([
    [PayInterval.NA, 'N/A'],
    [PayInterval.Hourly, 'Hourly'],
    [PayInterval.Annually, 'Annually'],
  ]);

  public static EducationEnum = new Map<number, string>([
    [Education.Unspecified, 'Unspecified'],
    [Education.SomeHighSchool, 'Some High School Coursework'],
    [Education.HighSchool, 'High School or Equivalent'],
    [Education.VocationalHighSchoolDiploma, 'Vocational - High School Diploma'],
    [Education.Certification, 'Certification'],
    [Education.Vocational, 'Vocational'],
    [Education.SomeCollege, 'Some College Coursework Completed'],
    [Education.Professional, 'Professional'],
    [Education.VocationalDegree, 'Vocational - Degree'],
    [Education.Associate, 'Associates'],
    [Education.Bachelors, 'Bachelors'],
    [Education.Masters, 'Masters'],
    [Education.Doctorate, 'Doctorate'],
  ]);

  public static CurrencyEnum = new Map<number, string>([
    [Currency.USD, 'United States Dollar (USD)'],
    [Currency.EUR, 'Euro (EUR)'],
    [Currency.GBP, 'British Pound (GBP)'],
    [Currency.CAD, 'Canadian Dollar (CAD)'],
    [Currency.AUD, 'Australian Dollar (AUD)'],
    [Currency.INR, 'Indian Rupee (INR)'],
  ]);

  public static ConferenceTaskStatusTypeEnum = new Map<number, string>([
    [ConferenceTaskStatusType.NotStarted, 'NotStarted'],
    [ConferenceTaskStatusType.InProgress, 'InProgress'],
    [ConferenceTaskStatusType.Completed, 'Completed'],
  ]);

  public static WowzaStateEnum = new Map<number, string>([
    [WowzaState.Started, 'Started'],
    [WowzaState.Stopped, 'Stopped'],
    [WowzaState.Starting, 'Starting'],
    [WowzaState.Stopping, 'Stopping'],
    [WowzaState.Resetting, 'Resetting'],
  ]);

}

export class OrganizationClaims {
     public static CanManageAdminUsers = "CanManageAdminUsers";
     public static CanManageAPIKeys = "CanManageAPIKeys";
     public static CanManageIntegrations = "CanManageIntegrations";
     public static CanManageConferences = "CanManageConferences";
     public static CanViewRegistrants = "CanViewRegistrants";
     public static CanAddRegistrants = "CanAddRegistrants";
     public static CanRefundRegistrants = "CanRefundRegistrants";
     public static CanViewConferenceEvents = "CanViewConferenceEvents";
     public static CanModifyConferenceEvents = "CanModifyConferenceEvents";
     public static CanJoinConferenceEventAsModerator = "CanJoinConferenceEventAsModerator";
     public static CanViewExhibits = "CanViewExhibits";
     public static CanModifyExhibits = "CanModifyExhibits";
     public static CanViewPackages = "CanViewPackages";
     public static CanModifyPackages = "CanModifyPackages";
     public static CanViewPromoCodes = "CanViewPromoCodes";
     public static CanModifyPromoCodes = "CanModifyPromoCodes";
     public static CanViewCEUWizard = "CanViewCEUWizard";
     public static CanProcessCEUs = "CanProcessCEUs";
     public static CanPrintQRCodes = "CanPrintQRCodes";
     public static CanScanInUsers = "CanScanInUsers";
     public static CanViewWebsiteBuilder = "CanViewWebsiteBuilder";
     public static CanModifyPages = "CanModifyPages";
     public static CanModifyTheme = "CanModifyTheme";
     public static CanViewAbstractTools = "CanViewAbstractTools";
     public static CanModifyAbstractSubmissions = "CanModifyAbstractSubmissions";
     public static CanViewPaperTypes = "CanViewPaperTypes";
     public static CanModifyPaperTypes = "CanModifyPaperTypes";
     public static CanModifyAbstractSettings = "CanModifyAbstractSettings";
     public static CanSeePaperReviewsWithoutSubmitting = "CanSeePaperReviewsWithoutSubmitting";
     public static CanSeePaperReviewsOnceSubmitted = "CanSeePaperReviewsOnceSubmitted";
     public static CanViewOrganizationEvents = "CanViewOrganizationEvents";
     public static CanModifyOrganizationEvents = "CanModifyOrganizationEvents";
     public static CanJoinOrganizationEventsAsModerator = "CanJoinOrganizationEventsAsModerator";
     public static CanViewOrganizationSpeakers = "CanViewOrganizationSpeakers";
     public static CanModifyOrganizationSpeakers = "CanModifyOrganizationSpeakers";
}
