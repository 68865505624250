import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConferenceViewModel, EventViewModel, MemberLookupResult, RegistrationPackageViewModel, StringViewModel } from 'src/app/_models/generatedModels';
import { ConferenceCMSService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-member-lookup-modal',
  templateUrl: 'member-lookup-modal.component.html',
})
export class MemberLookupModalComponent implements OnInit {
  initialized = false;
  @Input() conference: ConferenceViewModel;
  @Input() pckg: RegistrationPackageViewModel;
  @Input() courseEvent: EventViewModel;

  memberError = '';
  searchText: string;
  submitComplete: Promise<{}> | undefined;
  memberResult: MemberLookupResult;

  constructor(public activeModal: NgbActiveModal, private conferenceCMSService: ConferenceCMSService) {}

  ngOnInit(): void {
    this.initialized = true;
  }

  cancel() {
    this.activeModal.dismiss();
  }

  applyMembership() {
    this.memberResult.discountApplied = false;
    this.activeModal.close(this.memberResult);
  }

  applyVoucher() {
    this.memberResult.discountApplied = true;
    this.activeModal.close(this.memberResult);
  }

  lookupMember() {
    var model = new StringViewModel();
    model.value = this.searchText;
    this.submitComplete = new Promise<{}>((resetButton: any, reject) => {
      var subsciption = this.conference ? this.conferenceCMSService.externalMemberLookup(this.conference.id.toString(), model) : this.conferenceCMSService.externalMemberLookupCourseEvent(this.courseEvent.id, model);
      subsciption.subscribe((result) => {        this.memberResult = result;
        if (result.success) {
          resetButton();
          this.memberError = '';
        } else {
          resetButton()
          this.memberError = result.message;
        }
      }, error=>{
        resetButton();
        this.memberError = 'An error has occured. Please try again later.';
      });
    });
  }
}
