import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { LiveEventViewModel, UserAuthenticationViewModel, ChatHistoryViewModel, UserViewModel, TapBackViewModel, GroupedTapBackViewModel, EventViewModel } from 'src/app/_models/generatedModels';
import { HubConnection } from '@microsoft/signalr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ChatViewModel, EventRole, GoogleObj } from 'src/app/_models/models';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { EventService } from 'src/app/_services/generatedServices';
import { throws } from 'assert';
import { GoogleTranslateService } from 'src/app/_services/googleTranslate.service';
import { runInThisContext } from 'vm';
declare var UAParser: any;
//https://www.neoito.com/blog/how-to-maintain-scroll-position-in-angular-chat-app/
//https://stackoverflow.com/questions/35232731/angular-2-scroll-to-bottom-chat-style

@Component({
  selector: 'live-event-chat',
  templateUrl: 'live-event-chat.component.html',
})
export class LiveEventChatComponent implements OnInit, AfterViewInit {
  initialized = false;
  @Input() event: LiveEventViewModel;
  @Input() connection: HubConnection;
  @Input() hideCarat: boolean = false;
  @Input() role: EventRole;
  public EventRole = EventRole;

  @ViewChild('messageContainer') private messageContainer: ElementRef;
  @ViewChild('chatbox') private chatbox: ElementRef;

  public user: UserAuthenticationViewModel;
  public messages: ChatViewModel[] = new Array();
  public exisitingMessages: ChatHistoryViewModel[] = new Array();
  public chatMessage: string;
  toggled: boolean = false;
  showPicker: boolean = false;
  loadError: boolean = false;
  loading: boolean = false;

  @Output()
  resized = new EventEmitter();

  @Output()
  collapse = new EventEmitter();
  isNearBottom: boolean;
  isMobile: boolean;

  constructor(private auth: AuthenticationService, private eventService: EventService, private googleTranslateService: GoogleTranslateService) {}
  ngAfterViewInit(): void {
    of(null)
      .pipe(delay(5000))
      .subscribe((result) => {
        this.onResize(null);
        this.scrollToBottom(false);
      });
  }

  reload() {
    this.loading = true;
    this.eventService.getChatHistory(this.event.id).subscribe(
      (result) => {
        this.exisitingMessages = result;
        this.initialized = true;
        this.loadError = false;
        this.loading = false;
        this.scrollToBottom(false);
      },
      (error) => {
        this.loadError = true;
        this.loading = false;
      }
    );
  }

  ngOnInit(): void {
    this.user = this.auth.getUser();
    this.isMobile = this.isMobileDevice();

    this.connection.on('postChat', (id: number, name: string, message: string, userId: number, eventId: number) => {
      if (eventId === this.event.id) {
        var model = new ChatViewModel(id, name, message, userId);

        let regexp = new RegExp('>(https://behaviorlive.com/events/|https://behaviorlive.com/courses/|https://behaviorlive.com/on-demand/)(.*)<');
        var result = regexp.exec(message);

        if (result) {
          model.eventLink = result != null;
          model.eventLinkId = result[2];
        }

        if (this.auth.isSuperUser()) {
          var goog = new GoogleObj();
          goog.q = [model.message];
          goog.target = 'en';
          this.googleTranslateService.translate(goog).subscribe((result) => {
            if (result.data && result.data.translations && result.data.translations.length > 0 && result.data.translations[0].detectedSourceLanguage != 'en') {
              model.message += '<br>-----<br>' + result.data.translations[0].translatedText;
            }
          });
        }

        this.messages.push(model);
        this.scrollToBottom(userId !== this.user.id);
      }
    });

    this.connection.on('tapBack', (id: number, tapBacks: GroupedTapBackViewModel[], eventId: number) => {
      if (eventId === this.event.id) {
        var index = this.messages.findIndex((x) => x.id == id);
        if (index != -1) {
          this.messages[index].tapBacks = tapBacks;
          this.scrollToBottom(false);
          return;
        }

        var index2 = this.exisitingMessages.findIndex((x) => x.id == id);
        if (index2 != -1) {
          this.exisitingMessages[index2].tapBacks = tapBacks;
          this.scrollToBottom(false);
        }
      }
    });

    this.connection.on('showToken', (eventId: number, token: string, tokenNumber: number) => {
      if (eventId !== this.event.id) {
        return;
      }
      console.log('show token');
      if (this.isMobileDevice()) {
        console.log('is mobile device');
        return;
      }

      let model = new ChatViewModel(0, 'SYSTEM MESSAGE', `CEU Attendance Check #${tokenNumber} NOW SHOWING at the top of your screen`, 2);
      model.systemMessage = true;
      this.messages.push(model);
      this.scrollToBottom(false);
    });

    this.connection.on('tipReceived', (eventId: number, name: string) => {
      if (eventId !== this.event.id) {
        return;
      }
      let model = new ChatViewModel(0, 'SYSTEM MESSAGE', `${name} just sent a donation. Thank you!`, 2);
      model.systemMessage = true;
      this.messages.push(model);
      this.scrollToBottom(false);
    });

    this.reload();
  }

  click() {
    console.log('clicked');
  }

  tapBack(messageId: number, emojii: string) {
    this.connection.invoke('tapBack', messageId, emojii, this.user.id, this.event.id);
  }

  isMobileDevice(): boolean {
    let parser = new UAParser();
    let result = parser.getResult();

    if (result.os.name == 'Mac OS' || result.os.name == 'Windows') return false;

    return true;
  }

  public sendMessage() {
    this.connection.invoke('postChat', this.user.firstName + ' ' + this.user.lastName, this.chatMessage, this.user.id, this.event.id);
    this.chatMessage = null;
  }

  public sendAndTranslate() {
    var message = this.chatMessage;
    var goog = new GoogleObj();
    goog.q = [message];
    goog.target = 'es';
    this.googleTranslateService.translate(goog).subscribe((result) => {
      this.connection.invoke('postChat', this.user.firstName + ' ' + this.user.lastName, result.data.translations[0].translatedText, this.user.id, this.event.id);
      this.chatMessage = null;
    });
  }

  onScroll(event) {
    this.isNearBottom = this.isUserNearBottom();
  }

  private isUserNearBottom(): boolean {
    const threshold = 50;
    const position = this.messageContainer.nativeElement.scrollTop + this.messageContainer.nativeElement.offsetHeight;
    const height = this.messageContainer.nativeElement.scrollHeight;
    return position > height - threshold;
  }

  handleSelection(char) {
    if (this.chatMessage == null) {
      this.chatMessage = '';
    }

    this.chatMessage = this.chatMessage + char;
    this.toggled = false;
    this.chatbox.nativeElement.focus();
  }

  public scrollToBottom(check: boolean): void {
    of(null)
      .pipe(delay(500))
      .subscribe((result) => {
        this.messageContainer.nativeElement.scroll({
          top: this.messageContainer.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
        this.isNearBottom = true;
      });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    let windowHeight = window.innerHeight;
    let messageHeight = this.messageContainer.nativeElement.getBoundingClientRect().top;

    this.messageContainer.nativeElement.style.height = windowHeight - messageHeight - 58 + 'px';
    this.resized.emit();
  }

  collapseChat() {
    this.collapse.emit();
  }
}
