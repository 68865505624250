<page-load-header *ngIf="!initialized"></page-load-header>
<div *ngIf="initialized" class="modal-content">
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="h6 mb-0">Membership Lookup</h3>

      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <form #theForm="ngForm" novalidate>
    <div class="modal-body">
      <div class="form-row">
        <div class="col-md-12">
          <label *ngIf="conference" for="uname1">Enter the email address associated with your {{ conference.organization.shortName }} account.</label>
          <label *ngIf="courseEvent" for="uname1">Enter the email address associated with your {{ courseEvent.organizationName }} account.</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="user@example.com" name="searchText" [(ngModel)]="searchText" aria-describedby="button-addon2" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="lookupMember()" [promiseBtn]="submitComplete" type="button" id="button-addon2">Search</button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="memberResult?.success" class="alert alert-success">
        <div *ngIf="memberResult.remainingDiscounts > 0">
          You are eligible for the member price of: {{ pckg.price | currency: pckg.currencyCode }}.<br />
          <strong>{{ memberResult.organizationName }}</strong> has {{ memberResult.remainingDiscounts }}, {{ memberResult.discountPercentage | percent }} off voucher(s) remaining. If you are authorized by your business owner, you can use one for a total price of {{ pckg.price - pckg.price * memberResult.discountPercentage | currency: pckg.currencyCode }}.
        </div>
        <div *ngIf="memberResult.remainingDiscounts == 0">You are eligible for the member price of:
          <span *ngIf="!courseEvent">{{ pckg.price | currency: pckg.currencyCode }}</span>
          <span *ngIf="courseEvent">{{ courseEvent.memberPrice | currency: courseEvent.currencyCode }}</span>
          . Click the button below to apply the member price to your order.</div>        <div class="d-flex justify-content-around mt-2">
          <button type="button" class="btn btn-admin-primary mr-2" (click)="applyMembership()">Apply Member Rate</button>
          <button *ngIf="memberResult.totalDiscounts > 0 && memberResult.remainingDiscounts > 0" type="button" class="btn btn-admin-primary" (click)="applyVoucher()">Claim {{ memberResult.discountPercentage | percent }} Voucher (1 of {{ memberResult.totalDiscounts }})</button>
        </div>
      </div>
      <div *ngIf="memberError" class="alert alert-danger">
        <div>{{ memberError }}</div>
      </div>
    </div>

    <div class="modal-footer justify-content-start">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-hidden="true" (click)="cancel()">Cancel</button>
    </div>
  </form>
</div>
