import { Component, OnInit, Input } from '@angular/core';
import { CourseEventViewModel, EventViewModel } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'bxl-event-item',
  templateUrl: 'event-item.component.html',
})
export class EventItemComponent implements OnInit {
  @Input() event: EventViewModel;
  @Input() rootRoute: string = "/";
  initialized = false;
  timezone: string;
  constructor(public auth: AuthenticationService) {}

  ngOnInit(): void {
    this.initialized = true;

    let timeZone = moment.tz.guess();
    let timeZoneOffset = new Date(this.event.startTime).getTimezoneOffset();
    this.timezone = moment.tz(timeZone).format('z');
  }
}
