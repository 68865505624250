<!--component html goes here -->
<!--component html goes here -->
<page-load-header *ngIf="!initialized"></page-load-header>
<ng-container *ngIf="initialized">
  <div #fullscreenContainer>

    <header>
      <!-- Fixed navbar -->
      <nav class="navbar nowrap navbar-expand-md navbar-light bg-white">
        <a class="navbar-brand event-title text-truncate cursor-pointer"
        >{{ event.title }} - {{ event.instructor.fullName }}
          <span *ngIf="event.instructor.pronouns"> ({{ event.instructor.pronouns }})</span></a
        >
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <!-- <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#">Disabled</a>
          </li>
        </ul> -->
          <div class="form-inline ml-auto mt-2 mt-md-0">
            <button type="button" (click)="fullscreen()" class="btn btn-icon text-muted transition-3d-hover">
              <h3>
                <span class="fas fa-compress-wide btn-icon__inner"></span>
              </h3>
            </button>

            <button type="button" class="btn btn-icon" (click)="exitPage()">
              <h3 class="m-0 text-muted">
                <i class="fas fa-times"></i></h3>
            </button>
          </div>
        </div>
      </nav>
    </header>

    <main id="live-event" class="main-container">
      <div class="main-theater">
        <div class="row no-gutters">
          <div class="col text-center video-container" [ngClass]="{ previewing: previewing }">
            <div *ngIf="(inGreenRoom && !event.streamingStarted) || (streamCount > 0 && !event.streamingStarted)" class="alert progress-bar-striped progress-bar-animated bg-success green-room-message">
              <p class="text-left text-white mb-0">
                <span class="h5"><i class="fas fa-info-circle"></i> {{ 'youre-in-the-green-room' | translate }}</span>
                <span> {{ 'green-room-sub-message' | translate }}</span>
              </p>
            </div>
            <div *ngIf="previewing" class="alert progress-bar-striped progress-bar-animated bg-warning green-room-message">
              <div class="text-left text-white mb-0 d-flex justify-content-between align-items-center">
                <div>
                  <span class="h5"><i class="fas fa-info-circle"></i> Preview Stream</span>
                  <span> Verify audio & video. When you are ready to go, click "Start Streaming" to start the broadcast.</span>
                </div>
                <button type="button" class="btn btn-white btn-sm" [promiseBtn]="startStreamPromise" (click)="startRTMPStream()">Start Streaming</button>
              </div>
            </div>
            <div *ngIf="role == EventRole.Viewer && event.allowJoin && !event.streamingStarted && !event.streamingFinished && event.isWebRTC" class="alert progress-bar-striped progress-bar-animated bg-dark green-room-message">
              <p class="text-left text-white mb-0">
                <span class="h5"><i class="fas fa-info-circle"></i> {{ 'the-presenter-s-are-getting-ready' | translate }}</span>
                <span> {{ 'the-event-will-start-shortly' | translate }}</span>
              </p>
            </div>
            <div *ngIf="role == EventRole.Viewer && event.allowJoin && event.streamingFinished" class="alert progress-bar-striped progress-bar-animated bg-dark green-room-message">
              <p class="text-left text-white mb-0">
                <span class="h5"><i class="fas fa-info-circle"></i> {{ 'already-aired' | translate }}</span>
              </p>
            </div>

            <img *ngIf="!playingVideo && isWebRTC && event.sponsorBugImage" class="sponsor" (click)="cancelBug()" [@fadeBug]="bugShowing" src="https://bxlimages.blob.core.windows.net/images/{{ this.event.sponsorBugImage }}"/>
            <div *ngIf="!isWebRTC" #wowzaPlayer id="wowzaPlayer" class="azuremediaplayer amp-default-skin embed-responsive live-player live-player-fluid hide-fullscreen" [ngClass]="{ 'presenter-getting-ready': role == EventRole.Viewer && event.allowJoin && !event.streamingStarted && !event.streamingFinished && event.isWebRTC, previewing: previewing }" style="z-index: 0; background-color: black; display:flex; align-items: center">
              <img *ngIf="!livePlaying && !previewing" class="img-fluid" src="{{offAirImage}}">
            </div>
            <!--            <video *ngIf="!isWebRTC" #livePLayer id="livePLayer" class="azuremediaplayer amp-default-skin embed-responsive live-player live-player-fluid hide-fullscreen" [ngClass]="{ 'presenter-getting-ready': role == EventRole.Viewer && event.allowJoin && !event.streamingStarted && !event.streamingFinished && event.isWebRTC, previewing: previewing }" style="z-index: 0" poster="{{ offAirImage }}" autoplay></video>-->

            <div *ngIf="currentToken" class="CEUToken">
              <div>
                <h3 class="text-center">
                  CEU {{ 'attendance-check' | translate }} ({{ currentToken.tokenNumber }} {{ 'of' | translate }} 4)
                  <button type="button" class="btn btn-light" [promiseBtn]="submitTokenComplete" (click)="submitToken(currentToken)">{{ 'im-here' | translate }}</button>
                </h3>
              </div>
            </div>

            <div [ngClass]="{ invisible: !playingVideo }">
              <div #videoPlayer id="videoPlayer" class="azuremediaplayer amp-default-skin embed-responsive live-player event-video-player hide-fullscreen" autoplay></div>
            </div>
            <div *ngIf="isWebRTC" id="webRTCLayoutContainer" #webRTCLayoutContainer [ngClass]="{ greenroom: (inGreenRoom && !event.streamingStarted) || (streamCount > 0 && !event.streamingStarted), 'presenter-getting-ready': role == EventRole.Viewer && event.allowJoin && !event.streamingStarted && !event.streamingFinished }">
              &nbsp;
              <img *ngIf="streamCount == 0 && !publishing && !inGreenRoom && !attendeeCameraOn" class="img-fluid poster" src="{{ offAirImage }}"/>
            </div>
          </div>
          <footer class="live-footer">
            <div *ngIf="!connectedToSignalR && signalRInitialized && auth.isSuperUser()" style="z-index: 9999; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: #fff3cd; display: flex; justify-content: center; align-items: center">
              <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; opacity: 0.6;"></div>
              <div style="z-index: 10000">
                <div class="">Connection lost to chat server. You can keep streaming while we attempt to reconnect...</div>
              </div>
            </div>
            <div *ngIf="!connectedToSignalR && signalRFailed" style="z-index: 9999; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: #fff3cd; display: flex; justify-content: center; align-items: center">
              <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; opacity: 0.6;"></div>
              <div style="z-index: 10000">
                <div class="">Unable to connect to server...
                  <button class="btn btn-xs btn-dark" (click)="connectToSignalR()" type="button">Try Again</button>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col pl-2 pr-2">
                <div class="d-flex">
                  <div class="mr-auto">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <ng-container *ngIf="event.isWebRTC">
                          <li *ngIf="inGreenRoom && !event.streamingStarted && role == EventRole.Host" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Click to Go Live">
                            <button type="button" (click)="goLive()" class="btn btn-xs btn-success transition-3d-hover">
                              <i class="fas fa-circle"></i> {{ 'go-live' | translate }}
                            </button>
                          </li>
                          <li *ngIf="!inGreenRoom && role != EventRole.Viewer" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Prepare to Go Live">
                            <!--                            <button *ngIf="!auth.isSuperUser()" type="button" (click)="enterGreenRoom(false)" class="btn btn-xs btn-success transition-3d-hover">-->
                            <!--                              <i class="fas fa-play-circle"></i>-->
                            <!--                              <span *ngIf="role == EventRole.Host || role == EventRole.Guest"> {{ 'join-presentation' | translate }} </span>-->
                            <!--                            </button>-->
                            <div class="btn-group dropup">
                              <button type="button" (click)="enterGreenRoom(false)" class="btn btn-xs btn-success pr-0">
                                <i class="fas fa-play-circle"></i> {{ 'join-presentation' | translate }}
                              </button>
                              <button type="button" class="btn btn-xs btn-success dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-expanded="false"></button>
                              <div class="dropdown-menu p-0">
                                <a class="dropdown-item pt-0 pb-0" href="javascript:void(0)" (click)="enterGreenRoom(true)">Use Video Switcher</a>
                              </div>
                            </div>
                          </li>

                          <li *ngIf="inGreenRoom && !event.streamingStarted && role == EventRole.Host" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Exit Green Room">
                            <button type="button" (click)="exitGreenRoom()" class="btn btn-xs btn-outline-danger transition-3d-hover">
                              <i class="fas fa-video-slash"></i> {{ 'leave-presentation' | translate }}
                            </button>
                          </li>
                          <li *ngIf="inGreenRoom && role == EventRole.Guest" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Leave Video">
                            <button type="button" (click)="exitGreenRoom()" class="btn btn-xs btn-outline-danger transition-3d-hover">
                              <i class="fas fa-video-slash"></i> {{ 'leave-presentation' | translate }}
                            </button>
                          </li>

                          <li *ngIf="role == EventRole.Viewer && event.allowAttendeesOnCamera && event.streamingStarted" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Turn on Camera">
                            <button *ngIf="!attendeeCameraOn" type="button" (click)="attendeeJoinWithCamera()" class="btn btn-xs btn-success transition-3d-hover">
                              <i class="fas fa-camera-home"></i> {{ 'join-with-camera' | translate }}
                            </button>
                            <button *ngIf="attendeeCameraOn" type="button" (click)="attendeeLeaveCamera()" class="btn btn-xs btn-outline-danger transition-3d-hover">
                              <i class="fas fa-video-slash"></i> {{ 'leave-camera' | translate }}
                            </button>
                          </li>

                          <li *ngIf="event.streamingStarted && role == EventRole.Host" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="End Stream">
                            <div *ngIf="inGreenRoom" class="btn-group dropup">
                              <button type="button" (click)="endStream()" class="btn btn-xs btn-danger pr-0">
                                <i class="fas fa-video-slash"></i> {{ 'end-stream' | translate }}
                              </button>
                              <button type="button" class="btn btn-xs btn-danger dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-expanded="false"></button>
                              <div class="dropdown-menu p-0">
                                <a class="dropdown-item pt-0 pb-0" href="javascript:void(0)" (click)="exitGreenRoom()">{{ 'exit-green-room' | translate }}</a>
                              </div>
                            </div>
                            <button *ngIf="!inGreenRoom" type="button" (click)="endStream()" class="btn btn-xs btn-danger transition-3d-hover">
                              <i class="fas fa-video-slash"></i> {{ 'end-stream' | translate }}
                            </button>
                          </li>

                          <li *ngIf="inGreenRoom && !screenPublishing" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Share Screen">
                            <button type="button" (click)="shareScreen()" class="btn btn-xs btn-github transition-3d-hover">
                              <i class="fas fa-laptop"></i> {{ 'share-screen' | translate }}
                            </button>
                          </li>
                          <li *ngIf="screenPublishing" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Stop Sharing">
                            <button type="button" (click)="stopScreenSharing()" class="btn btn-xs btn-outline-warning transition-3d-hover">
                              <i class="fas fa-laptop"></i> {{ 'stop-sharing' | translate }}
                            </button>
                          </li>
                          <li *ngIf="role != EventRole.Viewer || event.allowAttendeesOnCamera" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Settings">
                            <button type="button" (click)="showDeviceSettings()" class="btn btn-xs btn-icon btn-secondary transition-3d-hover">
                              <span class="fas fa-cog btn-icon__inner"></span>
                            </button>
                          </li>

                          <li *ngIf="publishing && publishingVideo" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Settings">
                            <button type="button" (click)="toggleVideo()" class="btn btn-xs btn-icon btn-success transition-3d-hover">
                              <span class="fas fa-video"></span>
                            </button>
                          </li>
                          <li *ngIf="publishing && !publishingVideo" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Settings">
                            <button type="button" (click)="toggleVideo()" class="btn btn-xs btn-icon btn-primary transition-3d-hover">
                              <span class="fas fa-video-slash"></span>
                            </button>
                          </li>
                          <li *ngIf="publishing && publishingAudio" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Settings">
                            <button type="button" (click)="toggleAudio()" class="btn btn-xs btn-icon btn-success transition-3d-hover">
                              <span class="fas fa-microphone"></span>
                            </button>
                          </li>
                          <li *ngIf="publishing && !publishingAudio" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Settings">
                            <button type="button" (click)="toggleAudio()" class="btn btn-xs btn-icon btn-primary transition-3d-hover">
                              <span class="fas fa-microphone-slash"></span>
                            </button>
                          </li>
                          <li *ngIf="event.streamingStarted && timer$ | async as elapsed" class="list-inline-item mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Broadcast is running">
                            <span *ngIf="elapsed.pre" class="text-warning">INTRO</span>
                            <span *ngIf="!elapsed.pre" class="text-success">{{ 'live' | translate }}</span>
                            <span class="ml-2" style="font-family: courier; color: white">{{ elapsed.hours | LeftPadFilter }}:{{ elapsed.minutes | LeftPadFilter }}:{{ elapsed.seconds | LeftPadFilter }}</span>
                          </li>
                        </ng-container>

                        <li *ngIf="role == EventRole.Viewer && event.isWebRTC" class="list-inline-item mr-2 font-size-1" data-toggle="tooltip" data-placement="top" title="" data-original-title="Tech Support">
                          <!-- <p class="text-primary"><a class="cursor-pointer" (click)="techSupportClicked()">Help</a></p> -->
                          <div class="dropdown">
                            <a class="dropdown-toggle" style="line-height: 1rem" data-toggle="dropdown" href="javascript:void(0)">{{ 'Help' | translate }}</a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item" style="line-height: 1.5rem" (click)="toggleCompatabilityMode()" href="javascript:void(0)"><i class="mr-1 fad fa-hammer"></i> {{ 'compatibilty-mode' | translate }}</a>
                              <a class="dropdown-item" style="line-height: 1.5rem" (click)="reloadVideo()" href="javascript:void(0)"><i class="fas mr-1 fa-sync-alt"></i> {{ 'reload-video' | translate }}</a>
                              <a class="dropdown-item" style="line-height: 1.5rem" (click)="techSupportClicked()" href="javascript:void(0)"><i class="mr-1 fad fa-headset"></i> {{ 'get-support' | translate }}</a>
                            </div>
                          </div>
                        </li>
                        <li *ngIf="role == EventRole.Viewer || !event.isWebRTC" class="list-inline-item mr-2 font-size-1" data-toggle="tooltip" data-placement="top" title="" data-original-title="Tech Support">
                          <!-- <p class="text-primary"><a class="cursor-pointer text-light" (click)="enterCompatabilityMode()">Compatibility Mode</a></p> -->
                          <p class="text-primary">
                            <a class="cursor-pointer" (click)="techSupportClicked()">{{ 'Help' | translate }}</a>
                          </p>
                        </li>
                        <li *ngIf="role != EventRole.Viewer" class="list-inline-item mr-2 font-size-1" data-toggle="tooltip" data-placement="top" title="" data-original-title="Tech Support">
                          <!-- <p class="text-primary"><a class="cursor-pointer text-light" (click)="enterCompatabilityMode()">Compatibility Mode</a></p> -->
                          <button type="button" [promiseBtn]="submitComplete" (click)="requestModerator()" class="btn btn-xs btn-warning transition-3d-hover">
                           Request Moderator
                          </button>
<!--                          <p class="text-primary">-->
<!--                            <a class="cursor-pointer" (click)="requestModerator()">Request Moderator</a>-->
<!--                          </p>-->
                        </li>
                        <!-- <li class="list-inline-item mr-2 font-size-1" data-toggle="tooltip" data-placement="top" title="">
                          <div class="dropdown">
                            <a class="dropdown-toggle" style="line-height: 1rem;" data-toggle="dropdown" href="javascript:void(0)">{{ 'language' | translate }}</a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item" style="line-height: 1.5rem" (click)="changeLanguage('en')" href="javascript:void(0)">English</a>
                              <a class="dropdown-item" style="line-height: 1.5rem" (click)="changeLanguage('es')" href="javascript:void(0)">Español</a>
                            </div>
                          </div>
                        </li> -->
                      </ul>
                    </div>
                  </div>
                  <div class="d-block d-lg-none">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-3" data-toggle="tooltip" data-placement="top" title="" data-original-title="Chat">
                          <p class="text-primary">
                            <a [ngClass]="{ 'live-event-link-active': showChat }" class="cursor-pointer" (click)="showChat = !showChat; showQuestions = false; showPeople = false; showPolls = false; showCEUTokens = false; showVideos = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false">{{ 'chat' | translate }}</a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="role == EventRole.Viewer && (event.ceus | ceuCount) > 0" class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-3" data-toggle="tooltip" data-placement="top" title="" data-original-title="Access CEU tokens and generate certificates">
                          <p class="text-primary">
                            <a [ngClass]="{ 'live-event-link-active': showCEUTokens }" class="cursor-pointer" (click)="showCEUTokens = !showCEUTokens; showQuestions = false; showChat = false; showPeople = false; showPolls = false; showVideos = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false">CEUs</a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="auth.isSuperUser()" class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-3" data-toggle="tooltip" data-placement="top" title="" data-original-title="Access Video Playback">
                          <p class="text-primary">
                            <a [ngClass]="{ 'live-event-link-active': showSuperUser }" class="live-event-link cursor-pointer" (click)="showSuperUser = !showSuperUser; showVideos = false; showPolls = false; showQuestions = false; showChat = false; showPeople = false; showCEUTokens = false; showMaterials = false;showTechSupport = false">
                              <i class="fas fa-user-shield"></i>
                              <span class=""> Admin</span>
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="role != EventRole.Viewer || videoCount > 0" class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-3" data-toggle="tooltip" data-placement="top" title="" data-original-title="Access Video Playback">
                          <p class="text-primary">
                            <a [ngClass]="{ 'live-event-link-active': showVideos }" class="live-event-link cursor-pointer" (click)="showVideos = !showVideos; showPolls = false; showQuestions = false; showChat = false; showPeople = false; showCEUTokens = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false">
                              <i class="fas fa-tv "></i>
                              <span class=""> {{ 'videos' | translate }}</span>
                              <span *ngIf="unreadVideoCount > 0" class="badge badge-pill badge-danger">{{ unreadVideoCount }}</span>
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="role != EventRole.Viewer || materialCount > 0" class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-3" data-toggle="tooltip" data-placement="top" title="" data-original-title="Access Video Playback">
                          <p class="text-primary">
                            <a [ngClass]="{ 'live-event-link-active': showMaterials }" class="live-event-link cursor-pointer" (click)="showMaterials = !showMaterials; showVideos = false; showPolls = false; showQuestions = false; showChat = false; showPeople = false; showCEUTokens = false; showSuperUser = false; showTips = false;showTechSupport = false">
                              <i class="fas fa-paperclip "></i>
                              <span class=""> {{ 'handouts' | translate }}</span>
                              <span *ngIf="unreadMaterialCount > 0" class="badge badge-pill badge-danger ml-1 d-none d-xl-inline">{{ unreadMaterialCount }}</span></a
                            >
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="role != EventRole.Viewer || pollCount > 0" class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-3" data-toggle="tooltip" data-placement="top" title="" data-original-title="Access user polling">
                          <p class="text-primary">
                            <a [ngClass]="{ 'live-event-link-active': showPolls }" class="live-event-link cursor-pointer" (click)="showPolls = !showPolls; showQuestions = false; showChat = false; showPeople = false; showCEUTokens = false; showVideos = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false">
                              <i class="far fa-poll-people "></i>
                              <span class=""> {{ 'polls' | translate }}</span>
                              <span *ngIf="unreadPollCount > 0" class="badge badge-pill badge-danger ml-1 d-none d-xl-inline">{{ unreadPollCount }}</span>
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="event.showRequests || event.showQuestions" class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-3" data-toggle="tooltip" data-placement="top" title="">
                          <p class="text-primary">
                            <a [ngClass]="{ 'live-event-link-active': showQuestions }" class="live-event-link cursor-pointer" (click)="showQuestions = !showQuestions; showChat = false; showCEUTokens = false; showVideos = false; showPeople = false; showPolls = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false">
                              <i *ngIf="event.showQuestions" class="fas fa-question-circle"></i>
                              <span *ngIf="event.showQuestions" class="">{{ 'questions' | translate }}</span>
                              <span *ngIf="event.showRequests">Song Requests</span>
                              <span *ngIf="unreadQuestionCount > 0" class="badge badge-pill badge-danger ml-1 d-none d-xl-inline">{{ unreadQuestionCount }}</span>
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="event.showTips" class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-3" data-toggle="tooltip" data-placement="top" title="">
                          <p class="text-primary">
                            <a [ngClass]="{ 'live-event-link-active': showTips }" class="cursor-pointer" (click)="showTips = !showTips; showQuestions = false; showChat = false; showCEUTokens = false; showVideos = false; showPeople = false; showPolls = false; showSuperUser = false; showMaterials = false;showTechSupport = false">Donations </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div *ngIf="role != EventRole.Viewer" class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-1" data-toggle="tooltip" data-placement="top" title="">
                          <p class="text-primary">
                            <a class=" live-event-link cursor-pointer" [ngClass]="{ 'live-event-link-active': showPeople }" (click)="showPeople = !showPeople; showQuestions = false; showCEUTokens = false; showVideos = false; showChat = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false">
                              <i class="fas fa-user-circle "></i>
                              <span class=""> {{ 'people' | translate }}</span>
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="">
                    <div class="align-middle live-event-people" style="line-height: 0; padding-top: 14px; white-space: nowrap">
                      <ul class="list-inline mb-0">
                        <li *ngFor="let id of randomUserIds; let idx = index" class="list-inline-item mr-0" [ngClass]="{ 'ml-n3': idx > 0 }" data-toggle="tooltip" data-placement="top" title="" data-original-title="James Collins">
                          <div class="u-sm-avatar u-sm-avatar--bordered rounded-circle">
                            <img class="img-fluid rounded-circle" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ id }}-avatar.png"/>
                          </div>
                        </li>

                        <li *ngIf="userCount > 0" class="list-inline-item ml-n3 mr-0">
                          <a class="btn btn-sm btn-icon btn-light rounded-circle" type="button" (click)="showPeople = !showPeople">
                            <span *ngIf="userCount < 1000" class="btn-icon__inner">{{ userCount }}</span>
                            <span *ngIf="userCount >= 1000" class="btn-icon__inner">{{ userCount / 1000 | number: '1.1-1' }}k</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col chat-sidebar pt-1">
                <div class="input-group hide-when-small" [ngClass]="{ 'chat-collapsed': !chatCollapsed }">
                  <input type="text" class="form-control" (click)="openChat(child)" [(ngModel)]="child.chatMessage" (keyup.enter)="child.sendMessage()" placeholder="{{ 'type-a-message' | translate }}" aria-label="" aria-describedby="button-addon2"/>
                  <div class="input-group-append">
                    <button class="btn btn-white" (click)="child.toggled = !child.toggled; chatCollapsed = false" type="button" id="button-addon2">😄</button>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <div class="popover-window col" [ngClass]="{ 'd-none': !showQuestions }">
            <live-questions [event]="event" [parent]="this" [showInput]="true" [role]="role" (unreadChanged)="unreadChanged($event)" (closed)="showQuestions = false; showMaterials = false;showTechSupport = false" [connection]="connection"></live-questions>
          </div>

          <div class="popover-window col" [ngClass]="{ 'd-none': !showTips }">
            <live-tips [event]="event" (closed)="showTips = false"></live-tips>
          </div>

          <div class="popover-window col" [ngClass]="{ 'd-none': !showPolls }">
            <live-polls [event]="event" [role]="role" [showInput]="true" (unreadChanged)="pollUnreadChanged($event)" (countChanged)="pollCountChanged($event)" (closed)="showPolls = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false" [connection]="connection"></live-polls>
          </div>

          <div class="popover-window left col" [ngClass]="{ 'd-none': !showTechSupport }">
            <bxl-technical-support [event]="event" [parent]="this" (closed)="showQuestions = false; showMaterials = false; showTechSupport = false"></bxl-technical-support>
            <!-- <live-polls [event]="event" [role]="role" [showInput]="true" (unreadChanged)="pollUnreadChanged($event)" (countChanged)="pollCountChanged($event)" (closed)="showPolls = false; showSuperUser = false; showTips = false; showMaterials = false" [connection]="connection"></live-polls> -->
          </div>

          <div class="popover-window col" [ngClass]="{ 'd-none': !showMaterials }">
            <bxl-event-materials [event]="event" [showInput]="true" [role]="role" (unreadChanged)="materialUnreadChange($event)" (countChanged)="materialCountChanged($event)" (closed)="showPolls = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false" [connection]="connection"></bxl-event-materials>
          </div>

          <div *ngIf="role == EventRole.Viewer" class="popover-window col" [ngClass]="{ 'd-none': !showCEUTokens }">
            <ceu-tokens #ceutokens [event]="event" [playChime]="!attendeeCameraOn" [connection]="connection" (tokenReceived)="tokenReceived($event)" (closed)="showCEUTokens = false; showVideos = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false"></ceu-tokens>
          </div>

          <div class="col chat-sidebar pt-1" [ngClass]="{ 'chat-collapsed': chatCollapsed, 'hide-chat-tab': !showChat }">
            <live-event-chat #child [event]="event" [role]="role" (resized)="chatResized($event)" (collapse)="collapseChat()" [connection]="connection"></live-event-chat>
          </div>

          <div *ngIf="role != EventRole.Viewer" class="popover-window col" [ngClass]="{ 'd-none': !showPeople }">
            <live-people [event]="event" [role]="role" [showInput]="true" (usersUpdated)="usersUpdated($event)" [connection]="connection" (closed)="showPeople = false; showPolls = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false"></live-people>
          </div>

          <div *ngIf="role != EventRole.Viewer" class="popover-window col" [ngClass]="{ 'd-none': !showVideos }">
            <live-videos #liveVideosComponent [event]="event" [role]="role" (localVideoPlayed)="localVideoPlayed($event)" (playVideoClicked)="playVideoClicked($event)" (stopVideoClicked)="stopVideoClicked($event)" (unreadChanged)="videoUnreadChanged($event)" (countChanged)="videoCountChanged($event)" [connection]="connection" (closed)="showPeople = false; showPolls = false; showVideos = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false"></live-videos>
          </div>

          <div *ngIf="auth.isSuperUser()" class="popover-window large col" [ngClass]="{ 'd-none': !showSuperUser }">
            <bxl-live-superuser [event]="event" [connection]="connection" (closed)="showPeople = false; showPolls = false; showVideos = false; showSuperUser = false; showTips = false; showMaterials = false;showTechSupport = false"></bxl-live-superuser>
          </div>
        </div>
      </div>
    </main>
  </div>
  <!-- <footer class="live-footer">
    <div class="container-fluid">
      <p>hello</p>
    </div>

  </footer> -->
</ng-container>
